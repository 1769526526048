<template>
  <div
    class="content d-flex flex-column align-center justify-center overflow-y-auto"
    style="row-gap: 20px; max-height: calc(100vh - 350px)"
  >
    <img :src="sendImage" :width="500" />
    <div class="text-h4" v-if="!sending">אם הכל מוכן אז...</div>
    <div class="text-h4" v-else>
      {{ $t('customer.orders.activatingOrder') }}
    </div>
    <cz-button
      class="mt-6 font-weight-bold text-title"
      :height="60"
      :title="$t('customer.orders.sendOrderButtonTitle')"
      x-large
      :elevation="4"
      color="primary"
      :icon-src="mdiSendCheckOutline"
      :icon-size="22"
      @click="$emit('send')"
      :loading="sending"
      :disabled="!canSendOrder"
    />
  </div>
</template>

<script>
import sendImage from '@/assets/illustrations/target.svg';
import { CzButton } from '@/components';
import { mdiSendCheckOutline } from '@mdi/js';
export default {
  name: 'OrderSummary',
  components: {
    CzButton
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    sending: {
      type: Boolean
    },
    canSendOrder: {
      type: Boolean
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  created() {},
  mounted() {},
  data() {
    return {
      mdiSendCheckOutline,
      sendImage
    };
  }
};
</script>

<style lang="scss" scoped>
.content {
  height: calc(100vh - 300px);
}
</style>
